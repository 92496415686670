<template>
  <!-- Debugger Logs -->
  <SlideOver v-if="form.debug_mode"
             name="logsModal"
             :object="results"
             title="Logs">
    <template #default>
      <Logs :logs="results.infoLogs"/>
    </template>

    <template #buttons>
      <button
        class="btn-nav"
        @click="copyInfoLogs">
        <CheckIcon v-if="copiedLogsInfo" class="h-6 w-6" aria-hidden="true"/>
        <ClipboardIcon v-if="!copiedLogsInfo && results.infoLogs.length != 0" class="h-6 w-6" aria-hidden="true"/>
      </button>
      <input id="copy-info-logs" type="hidden" :value="results.infoLogs">
    </template>
  </SlideOver>

  <!-- Execution -->
  <div v-if="results.executing && !results.showResults"
       class="flex flex-col items-center justify-center select-none mt-[10%]"
  >
    <div>
      <CircleProgressbar :progress="results.progressbar.current"/>
    </div>

    <h3 v-if="!results.exception.error" class="mt-8 animate-pulse" v-text="remainingTimeText"/>

    <div class="mt-8">
      <button data-cy="backtest-cancel-button" class="flex justify-center items-center btn-cancel mb-4 w-64"
              @click="cancel($route.params.id)">
        <BanIcon class="w-5 h-5 mr-2"/>
        Cancel
      </button>

      <a v-if="form.debug_mode && results.exception.error && results.progressbar.current !== 0"
         :href="logsUrl"
         class="flex justify-center items-center btn-secondary text-center mb-4 w-full">
        <DocumentDownloadIcon class="w-5 h-5 mr-2"/>
        Debugging Logs
      </a>
    </div>

    <!-- exception  -->
    <div v-if="results.exception.error && results.executing" class="mx-auto container mt-8">
      <Exception :title="results.exception.error"
                 :content="results.exception.traceback"
                 mode="backtest"
                 :debug-mode="form.debug_mode"
                 :session-id="results.generalInfo.session_id"/>
    </div>
  </div>

  <LayoutWithSidebar else>
    <template #left>
      <!-- alert -->
      <div v-if="results.showResults && results.alert.message">
        <Alert :data="results.alert"/>
      </div>

      <!-- Content -->
      <div v-if="!results.executing && !results.showResults" data-cy="backtest-content-section">
        <Routes v-if="isInitiated" :form="form" :results="results"/>

        <Divider class="mt-16" title="Options"/>

        <div data-cy="backtest-option-section" class="grid grid-cols-1 gap-6">
          <!-- debug mode -->
          <ToggleButton
            :object="form"
            name="debug_mode"
            title="Debug Mode"
            description="Logs every step of the execution. Very helpful for debugging your strategy but takes a lot longer to execute"/>

          <!-- export chart -->
          <ToggleButton
            :object="form"
            name="export_chart"
            title="Export Charts"
            description="Exports charts for your portfolio's daily balance."/>

          <!-- export trading view -->
          <ToggleButton
            :object="form"
            name="export_tradingview"
            title="Export Tradingview"
            description="Exports the executed trades in a format accepted by TradingView's Pine Editor. Useful to look at the executed orders on their chart."/>

          <!-- export full reports -->
          <ToggleButton
            :object="form"
            name="export_full_reports"
            title="Export QuantStats reports"
            description="Exports a HTML file generated by QuantStats library."/>

          <!-- export csv -->
          <ToggleButton
            :object="form"
            name="export_csv"
            title="Export CSV"
            description="Exports executed trades in a CSV format"/>

          <!-- export json -->
          <ToggleButton
            :object="form"
            name="export_json"
            title="Export JSON"
            description="Exports executed trades in a JSON format"/>
        </div>

        <Divider class="mt-16" title="Duration"/>

        <div class="flex items-center select-none flex-1 mb-4">
          <input id="start_date"
                 v-model="form.start_date"
                 data-cy="backtest-start-date"
                 type="date"
                 name="start_date"
                 class="dark:bg-backdrop-dark dark:border-gray-900 dark:hover:bg-gray-700 hover:bg-gray-50 flex-1 cursor-pointer focus:ring-indigo-500 focus:border-indigo-500 dark:focus:border-indigo-400 flex justify-center items-center w-48 py-4 text-center sm:text-sm border-gray-300 rounded-l-md border-r-0"
          >

          <input id="finish_date"
                 v-model="form.finish_date"
                 data-cy="backtest-finish-date"
                 type="date"
                 name="finish_date"
                 class="dark:border-gray-900 dark:hover:bg-gray-700 hover:bg-gray-50 dark:bg-backdrop-dark flex-1 cursor-pointer focus:ring-indigo-500 focus:border-indigo-500 dark:focus:border-indigo-400 flex justify-center items-center w-48 py-4 text-center sm:text-sm border-gray-300 rounded-r-md">
        </div>
      </div>

      <!-- Results -->
      <div v-if="results.showResults"
           class="w-full mx-auto">
        <div>
          <Divider title="Routes"/>
          <MultipleValuesTable :data="results.routes_info" header/>

          <Divider v-if="results.hyperparameters.length" class="mt-16" title="Hyperparameters"/>
          <KeyValueTable v-if="results.hyperparameters.length" :data="results.hyperparameters"/>

          <Divider v-if="hasExecutedTrades" class="mt-16" title="Equity Curve"/>
          <EquityCurve v-if="hasExecutedTrades" :equity-curve="results.charts.equity_curve"/>

          <Divider v-if="hasExecutedTrades" class="mt-16" title="Performance"/>
          <KeyValueTable v-if="hasExecutedTrades" :data="results.metrics"/>

          <div v-if="!hasExecutedTrades"
               class="text-yellow-500 border-yellow-400 bg-yellow-50 dark:bg-gray-700 dark:text-yellow-400 mt-16 text-center text-2xl rounded border-2 border-dashed dark:border-gray-800 py-16 select-none"
          >
            No trades were executed via this strategy!
          </div>
        </div>
      </div>
    </template>

    <template #right>
      <!-- Action Buttons -->
      <div v-if="!results.executing">
        <div v-if="results.showResults">
          <button class="flex justify-center items-center btn-primary text-center mb-4 w-full"
                  @click="rerun($route.params.id)">
            <RefreshIcon class="w-5 h-5 mr-2"/>
            Rerun
          </button>

          <button class="flex justify-center items-center btn-success text-center mb-4 w-full"
                  @click="newBacktest($route.params.id)">
            <ReplyIcon class="w-5 h-5 mr-2"/>
            New session
          </button>

          <a v-if="form.debug_mode"
             :href="logsUrl"
             target="_blank"
             class="flex justify-center items-center btn-secondary text-center mb-4 w-full">
            <DocumentDownloadIcon class="w-5 h-5 mr-2"/>
            Debugging Logs
          </a>

          <a v-if="form.export_chart && hasExecutedTrades"
             :href="legacyChartUrl"
             target="_blank"
             class="flex justify-center items-center btn-secondary text-center mb-4 w-full">
            <DocumentDownloadIcon class="w-5 h-5 mr-2"/>
            Legacy Chart
          </a>

          <a v-if="form.export_full_reports && hasExecutedTrades"
             :href="fullReportsUrl"
             target="_blank"
             class="flex justify-center items-center btn-secondary text-center mb-4 w-full">
            <DocumentDownloadIcon class="w-5 h-5 mr-2"/>
            QuantStats Report
          </a>

          <a v-if="form.export_csv && hasExecutedTrades"
             :href="csvUrl"
             target="_blank"
             class="flex justify-center items-center btn-secondary text-center mb-4 w-full">
            <DocumentDownloadIcon class="w-5 h-5 mr-2"/>
            CSV
          </a>

          <a v-if="form.export_json && hasExecutedTrades"
             :href="jsonUrl"
             target="_blank"
             class="flex justify-center items-center btn-secondary text-center mb-4 w-full">
            <DocumentDownloadIcon class="w-5 h-5 mr-2"/>
            JSON
          </a>

          <a v-if="form.export_tradingview && hasExecutedTrades"
             :href="tradingviewUrl"
             target="_blank"
             class="flex justify-center items-center btn-secondary text-center mb-4 w-full">
            <DocumentDownloadIcon class="w-5 h-5 mr-2"/>
            TradingView Pine Editor
          </a>

          <hr class="my-8 border-2 dark:border-gray-600 rounded-full">

          <KeyValueTableSimple :data="results.info"/>
        </div>

        <div v-else>
          <button data-cy="start-button" class="flex items-center justify-center btn-primary text-center mb-4 w-full"
                  @click="start($route.params.id)">
            <LightningBoltIcon class="w-5 h-5 mr-2"/>
            Start
          </button>

          <button data-cy="start-new-tab-button"
                  class="flex items-center justify-center btn-secondary text-center mb-4 w-full"
                  @click="startInNewTab($route.params.id)">
            <PlusSmIcon class="w-5 h-5 mr-2"/>
            Start in a new tab
          </button>
        </div>
      </div>
    </template>
  </LayoutWithSidebar>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useBacktestStore } from '@/stores/backtest'
import Logs from '@/components/Logs'
import LayoutWithSidebar from '@/layouts/LayoutWithSidebar'
import MultipleValuesTable from '@/components/MultipleValuesTable'
import { useMainStore } from '@/stores/main'
import { ClipboardIcon, CheckIcon } from '@heroicons/vue/solid'
import {
  LightningBoltIcon,
  PlusSmIcon,
  RefreshIcon,
  ReplyIcon,
  DocumentDownloadIcon,
  BanIcon
} from '@heroicons/vue/outline'
import SlideOver from '@/components/Functional/SlideOver'
import ToggleButton from '@/components/ToggleButton'
import helpers from '@/helpers'
import Divider from '@/components/Divider'

export default {
  name: 'BacktestTab',
  components: {
    LayoutWithSidebar,
    Logs,
    ToggleButton,
    MultipleValuesTable,
    ClipboardIcon,
    CheckIcon,
    SlideOver,
    LightningBoltIcon,
    PlusSmIcon,
    RefreshIcon,
    ReplyIcon,
    DocumentDownloadIcon,
    BanIcon,
    Divider
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    results: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      copiedLogsInfo: false,
      svgObject: { display: false },
    }
  },
  computed: {
    legacyChartUrl () {
      let url = `/download/backtest/chart/${this.results.generalInfo.session_id}?token=${this.auth_key}`
      if (this.baseURL !== '/') {
        url = this.baseURL + url
      }
      return url
    },
    logsUrl () {
      let url = `/download/backtest/log/${this.results.generalInfo.session_id}?token=${this.auth_key}`
      if (this.baseURL !== '/') {
        url = this.baseURL + url
      }
      return url
    },
    tradingviewUrl () {
      let url = `/download/backtest/tradingview/${this.results.generalInfo.session_id}?token=${this.auth_key}`
      if (this.baseURL !== '/') {
        url = this.baseURL + url
      }
      return url
    },
    fullReportsUrl () {
      let url = `/download/backtest/full-reports/${this.results.generalInfo.session_id}?token=${this.auth_key}`
      if (this.baseURL !== '/') {
        url = this.baseURL + url
      }
      return url
    },
    csvUrl () {
      let url = `/download/backtest/csv/${this.results.generalInfo.session_id}?token=${this.auth_key}`
      if (this.baseURL !== '/') {
        url = this.baseURL + url
      }
      return url
    },
    jsonUrl () {
      let url = `/download/backtest/json/${this.results.generalInfo.session_id}?token=${this.auth_key}`
      if (this.baseURL !== '/') {
        url = this.baseURL + url
      }
      return url
    },
    hasExecutedTrades () {
      return this.results.metrics.length > 0
    },
    ...mapState(useMainStore, ['isInitiated', 'baseURL']),
    auth_key () {
      return sessionStorage.auth_key
    },
    remainingTimeText () {
      return helpers.remainingTimeText(this.results.progressbar.estimated_remaining_seconds)
    }
  },
  watch: {
    form: {
      handler () {
        helpers.localStorageSet('backtestForm', this.form)
      },
      deep: true
    },
  },
  methods: {
    ...mapActions(useBacktestStore, ['addTab', 'startInNewTab', 'start', 'cancel', 'rerun', 'newBacktest']),
    copyInfoLogs () {
      const infoLogsToCopy = document.querySelector('#copy-info-logs')
      infoLogsToCopy.setAttribute('type', 'text')
      infoLogsToCopy.select()
      document.execCommand('copy')
      this.copiedLogsInfo = true

      // unselect the range
      infoLogsToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()

      setTimeout(() => {
        this.copiedLogsInfo = false
      }, 3000)
    },
  },

}
</script>

