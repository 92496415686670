<template>
  <div class="border border-gray-300 dark:border-gray-700 rounded-md p-4 w-full mx-auto">
    <div class="animate-pulse flex space-x-4 mb-8">
      <div class="flex-1 space-y-4 py-1">
        <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4"/>
        <div class="space-y-2">
          <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded"/>
          <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-5/6"/>
        </div>
      </div>
      <div class="flex-1 space-y-4 py-1">
        <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4"/>
        <div class="space-y-2">
          <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded"/>
          <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-5/6"/>
        </div>
      </div>
    </div>
    <div class="animate-pulse flex space-x-4">
      <div class="flex-1 space-y-4 py-1">
        <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4"/>
        <div class="space-y-2">
          <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded"/>
          <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-2/6"/>
        </div>
      </div>
      <div class="flex-1 space-y-4 py-1">
        <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4"/>
        <div class="space-y-2">
          <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded"/>
          <div class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-4/6"/>
        </div>
      </div>
    </div>
  </div>
</template>
